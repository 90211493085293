import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Modal, Typography
} from "@material-ui/core";
import { imageGet } from "../../../helpers";
import { FaWhatsapp } from "react-icons/fa";

import "./style.css";
import ProductStocks from "../../ProductStocks";

const Header = ({ code, categoryName, images, products }) => {
  const img = imageGet(code, images);
  let height, width, system;
  if (products.length > 0) {
    const specs = products[0].specifications;
    height = specs.find((s) => s.specKey === "Alto");
    width = specs.find((s) => s.specKey === "Ancho");
    system = specs.find((s) => s.specKey == "Sistema");
    if (height) {
      height = height.specValue;
    }
    if (width) {
      width = width.specValue;
    }
    if (system) {
      system = system.specValue;
    }
  }
  return (
    <Row noGutters>
      <Col xs="auto">
        <div
          className="autopart-card-img"
          style={{ backgroundImage: `url(${img})` }}
        />
      </Col>
      {(height || width || system) && (
        <>
          <Col />
          <Col>
            Alto:
            <br />
            {height}
          </Col>
          <Col>
            Ancho:
            <br />
            {width}
          </Col>
          <Col>
            Sistema:
            <br />
            {system}
          </Col>
        </>
      )}
      <Col className="text-right">
        <small>Categoria:</small>
        <br />
        <span className="autopart-category">{categoryName}</span>
      </Col>
    </Row>
  );
};

const Applications = ({ applications }) => {
  const applicationsMax = 5;
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll(!showAll);
  const showMax = showAll ? applications.length + 1 : applicationsMax;
  const hasYearRange = applications.some(
    (a) =>
      a.applicationYearRange !== "" &&
      a.applicationYearRange.toLowerCase() !== "n/d"
  );

  return (
    <div className="autopart-applications">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Marca</TableCell>
            {hasYearRange && <TableCell>Año</TableCell>}
            <TableCell>Aplicación</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications
            .slice(0, showMax)
            .map(
              (
                { applicationBrandName, applicationYearRange, applicationName },
                i
              ) => (
                <TableRow key={i}>
                  <TableCell>{applicationBrandName}</TableCell>
                  {hasYearRange && (
                    <TableCell>{applicationYearRange}</TableCell>
                  )}
                  <TableCell>{applicationName}</TableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
      {applications.length > applicationsMax && (
        <Button fullWidth onClick={toggleShowAll}>
          {showAll ? "Ver menos" : "Ver mas"}
        </Button>
      )}
    </div>
  );
};


const Actions = ({ code }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleContact = (branch) => {
    const whatsappLinks = {
      branch1: `https://wa.me/098000600?text=Hola! Estoy interesado en el producto con código: ${code}`,
      branch2: `https://wa.me/091033282?text=Hola! Estoy interesado en el producto con código: ${code}`,
    };
    window.open(whatsappLinks[branch], "_blank");
    handleModalClose();
  };

  return (
    <div className="actions-container">
      <div className="text-right">
        <Button
          variant="contained"
          style={{
            backgroundColor: "#25D366",
            color: "white",
            marginLeft: "10px",
          }}
          onClick={handleModalOpen}
        >
          <FaWhatsapp style={{ fontSize: "28px", paddingRight: "8px" }} />
          Consultar por WhatsApp
        </Button>
        <Link to={`/productos/${code}`}>
          <Button variant="outlined" color="primary">
            Ver más
          </Button>
        </Link>
      </div>

      {/* Modal para elegir la sucursal */}
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "12px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
            padding: "24px",
            width: "400px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            id="modal-title"
            style={{ marginBottom: "16px" }}
          >
            Contactar por WhatsApp
          </Typography>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#25D366",
              color: "white",
              marginBottom: "12px",
              padding: "10px 0",
              fontSize: "16px",
              width: "100%",
            }}
            onClick={() => handleContact("branch1")}
          >
            <FaWhatsapp style={{ fontSize: "28px", paddingRight: "8px" }} />
            Nami Bulevar
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#25D366",
              color: "white",
              padding: "10px 0",
              fontSize: "16px",
              width: "100%",
            }}
            onClick={() => handleContact("branch2")}
          >
            <FaWhatsapp style={{ fontSize: "28px", paddingRight: "8px" }} />
            Nami Cerro Largo
          </Button>
        </div>
      </Modal>
    </div>
  );
};


class AutopartCard extends React.Component {
  render() {
    const { isLoggedIn, isSeller, autopart } = this.props;

    const { categoryName, code, images, applications, products } = autopart;

    const productStocks = products.map(({ productCode, productPrice }) => ({
      code: productCode,
      price: productPrice,
    }));

    return (
      <div className="autopart-card">
        <div className="card">
          <Header
            code={code}
            categoryName={categoryName}
            images={images}
            products={products}
          />
          <Applications applications={applications} />
          <ProductStocks
            label="Stock"
            isLoggedIn={isLoggedIn}
            isSeller={isSeller}
            products={productStocks}
          />
          <Actions code={code} />
        </div>
      </div>
    );
  }
}

export default withRouter(AutopartCard);

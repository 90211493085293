import React from "react";
import { Link } from "react-router-dom";

import { numberTypes, numberFormat, configSettings } from "base";

import Icon from "../../Icon";
import { imageGet } from "../../../helpers";

const QuantControl = (props) => {
  const { showActions, showStock, quantity, code, cartItemSetQuantity } = props;
  return (
    <div className="cart-quantity">
      {showActions && (
        <button
          disabled={quantity === 1}
          onClick={() => cartItemSetQuantity(code, quantity - 1)}
        >
          <Icon>minus</Icon>
        </button>
      )}
      <span>{quantity}</span>
      {showActions && (
        <button onClick={() => cartItemSetQuantity(code, quantity + 1)}>
          <Icon>plus</Icon>
        </button>
      )}
    </div>
  );
};

class CartRow extends React.Component {
  cartItemSetQuantity = (code, quantity) => {
    this.props.cartItemSetQuantity(code, quantity);
    if (this.props.showStock) this.props.getStock(code, quantity);
    this.props.getStock(code, quantity);
  };

  componentWillMount() {
    const { code, quantity } = this.props.item;
    if (this.props.showStock) this.props.getStock(code, quantity);
  }

  render() {
    const {
      showActions,
      showPrice,
      stockAvailable: stockAvailableValue,
      stockLoading,
      item,
      cartItemDelete,
    } = this.props;
    const stockAvailable =
      stockAvailableValue === "AV" || stockAvailableValue === "CO";
    const { code, imageCode, name, quantity, price } = item;

    const quantControl = (
      <QuantControl
        showActions={showActions}
        code={code}
        quantity={quantity}
        cartItemSetQuantity={this.cartItemSetQuantity}
      />
    );
    const priceSpan = (
      <span className="cart-price">
        <small>{configSettings.currencySymbol}</small>{" "}
        {numberFormat(
          numberTypes.PRICE,
          parseFloat(quantity) * parseFloat(price)
        )}
      </span>
    );
    const btnDelete = (
      <button className="cart-delete" onClick={() => cartItemDelete(code)}>
        <Icon outline>trash-alt</Icon>
      </button>
    );

    return (
      <React.Fragment>
        {!stockLoading && !stockAvailable && (
          <div className="tags">
            <div className="tag out-of-stock">Stock insuficiente</div>
          </div>
        )}
        <tr
          className={`${stockLoading || !stockAvailable ? "out-of-stock" : ""}`}
        >
          <td>
            <Link to={`/productos/${code}`}>
              <div
                className="cart-img"
                style={{
                  backgroundImage: `url(${imageGet(imageCode || code)})`,
                }}
              />
            </Link>
          </td>
          <td>
            <Link className="cart-code" to={`/productos/${code}`}>
              {code}
            </Link>
            <Link className="cart-name " to={`/productos/${code}`}>
              {name}
            </Link>

            <table className="table-tr-2 d-table d-sm-none">
              <tbody>
                <tr>
                  <td>{quantControl}</td>
                  <td className="text-right">{priceSpan}</td>
                  {showActions && <td>{btnDelete}</td>}
                </tr>
              </tbody>
            </table>
          </td>
          <td className="d-none d-sm-table-cell text-center">
            {quantControl}
            <br />
          </td>
          {showPrice && (
            <td className="d-none d-md-table-cell text-right">
              <span className="cart-price">
                <small>{configSettings.currencySymbol}</small>{" "}
                {numberFormat(numberTypes.PRICE, price)}
              </span>
            </td>
          )}
          <td className="d-none d-sm-table-cell text-right">{priceSpan}</td>
          {showActions && (
            <td className="d-none d-sm-table-cell">{btnDelete}</td>
          )}
        </tr>
      </React.Fragment>
    );
  }
}

export default CartRow;
